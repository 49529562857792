<template>
  <div>
    <b-card
      class="shadow p-2"
      style="padding-top:0.5rem !important;"
    >
      <b-overlay :show="$apollo.loading || mutationLoading">
        <b-row>
          <b-col md="2">
            <b-card class="d-flex flex-column">
              <h4 class="text-center mb-1">
                Documents
              </h4>
              <b-button
                v-for="(button, index) in btns"
                :key="index"
                block
                :variant="button.id == selectedBd ? 'primary' : 'outline-primary'"
                @click="selectedBd = button.id"
              >
                {{ button.title }}
              </b-button>
              <!-- <b-button
            v-b-modal.knowledgeBaseModal
            variant="primary"
            class="mt-1"
            style="width: 100%"
          >
            Add New
            <feather-icon icon="FileTextIcon" size="14" />
          </b-button> -->
            </b-card>
          </b-col>
          <b-col
            class=""
            md="10"
          >
            <b-card>
              <b-tabs class="">
                <div class="d-flex justify-content-between align-items-center ">
                  <b-button
                    :to="{name: 'startup-portal-growth-add', params: {gid: selectedBd}}"
                    variant="outline-primary"
                    class="mr-2"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add New Version
                  </b-button>
                  <div>
                    <b-button
                      variant="outline-primary"
                      class=""
                    >
                      <feather-icon icon="EyeIcon" />
                      View Sample
                    </b-button>
                  </div>
                </div>
                <!-- table -->
                <vue-good-table
                  class="mt-1"
                  :columns="columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                  :rows="vbd"
                >
                  <!-- pagination -->
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                        :to="{name:'startup-portal-growth-view', params:{id: props.row.id}}"
                      >
                        <span>Open</span>
                      </b-button>
                      <b-button
                        size="sm"
                        variant="flat-danger"
                        class="mr-1"
                        @click="selectedIndex=props.row.id;deleteversion()"
                      >
                        <feather-icon
                          icon="TrashIcon"
                        />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
                <div
                  v-if="selectedOrgId == null"
                  style="background-color:#F8F8F8d1;height:100%;width:100%;position:absolute;top:0px;left:0px;z-index:3;"
                  class="d-flex justify-content-center align-items-center"
                >
                  <h4>Please Select a Startup</h4>
                </div>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
      <b-modal
        id="knowledgeBaseModal"
        ok-only
        no-close-on-backdrop
        ok-title="Add"
        size="lg"
        title="Add New Version"
        @ok="addNewVersion"
      >
        <b-overlay :show="$apollo.loading || mutationLoading">
          <validation-observer
            ref="uploadForm"
            tag="form"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Title"
                  label-size="sm"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Title"
                      required
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-size="sm"
                >
                  <b-form-textarea
                    v-model="description"
                    placeholder="File Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-card>
    <b-modal
      id="selectStartup"
      ref="selectStartupModal"
      title="Select Startup"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        v-if="userStartups && userStartups.length > 0"
        :label-class="`h5`"
        label="Select Startup"
      >
        <v-select
          id="selectStartup"
          v-model="selectStartup"
          :get-option-label="e => e.users_organizationtable.title"
          :options="userStartups"
          :reduce="startup => startup.organization_id"
          placeholder="Choose one from the list"
          @input="$router.replace({name: $route.name, params: {aid: selectStartup }}); $refs.selectStartupModal.hide()"
        />
      </b-form-group>
      <div
        v-if="userStartups && userStartups.length > 0"
        class="d-flex justify-content-center align-items-center"
      >
        <hr class="w-100 mr-2">
        <span class="px-2 small text-muted font-weight-bold">OR</span>
        <hr class="w-100 ml-2">
      </div>
      <b-button
        :to="{name: 'startup-portal-details', query: { redirect: encodeURIComponent($route.fullPath) }}"
        class="my-50"
        block
        variant="flat-primary"
      >
        Add Startup
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import { getUserData } from '@/utils/auth'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
// import { ChevronsRightIcon } from 'vue-feather-icons'
import gql from 'graphql-tag'
// import { Promised } from 'vue-promised'
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    // BTabs,
    BCard,
    BButton,
    vSelect,
    // BCardText,
    VueGoodTable,
    // BCardBody,
    BPagination,
    // BAlert,
    // BCardTitle,
    // Promised,
    // BFormFile,
    // BFormCheckbox,
    BFormTextarea,
    // BCardSubTitle,
    // BOverlay,
    // // BCardHeader,
    BFormGroup,
    BFormSelect,
    BFormInput,
    // BImg,
    // BCardFooter,
    // BTable,
    // BLink,
    BCol,
    // BBadge,
    // BRow,
    // BModal,
    // ChevronsRightIcon,
    // PlusIcon,
    // CompassIcon,
    ValidationObserver,
    ValidationProvider,
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.resource === 'Auth' || getUserData().associatedOrgDetails) {
      next()
    }
    next(false)
  },
  data() {
    return {
      pageLength: 5,
      milestones: [],
      searchTerm: '',
      newload: true,
      selectedBd: null,
      btns: [],
      vbd: [],
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Created Date',
          field(row) {
            const dateNew = new Date(row.created_at)
            const options = { day: 'numeric', month: 'long', year: 'numeric' }
            const formattedDate = dateNew.toLocaleDateString('en-US', options)
            return formattedDate
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      title: null,
      description: '',
      is_internal: true,
      file_link: null,
      file: null,
      buttons: [
        { caption: 'Toggle 1', state: true },
        { caption: 'Toggle 2', state: false },
        { caption: 'Toggle 3', state: false },
        { caption: 'Toggle 4', state: false },
      ],
      programs: [],
      applynow: [],
      items: [
        {
          'Organized By': 'xyz', 'Title ': 'Startup India', 'Type ': 'Online', 'Sector ': 'xyz', 'Funding Assitance': 'xyz', 'Lifecycle Stage': 'xyz', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Duration ': 1000,
        },
      ],
      applications: [],
      mentorprograms: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      selectedIndex: null,
      userID: getUserData().id,
      mutationLoading: false,
      selectedOrgId: getUserData().associatedOrgDetails[0].organization_id,
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    getvbd() {
      this.$apollo.queries.vbd.refetch()
    },
    async addNewVersion() {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_growth_category_startup_entry_one (
            object: {
              startup_id: ${this.selectedOrgId},
              title: ${this.title},
              description: ${this.description},
              growth_categories_id: ${this.selectedBd},
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully added new version.',
            icon: 'CompassIcon',
            variant: 'success',
          },
        })

        this.$apollo.queries.vbd.refetch()
        this.mutationLoading = false
      }).catch(error => {
        // console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong. Please try again later.',
            message: error.message,
            icon: 'CompassIcon',
            variant: 'danger',
          },
        })
        this.mutationLoading = false
      })
    },
    deleteversion() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this version?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete Version',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_growth_category_startup_entry_by_pk(pk_columns: {id: ${this.selectedIndex}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.vbd.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_in: [${this.userOrgs}]}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applynow: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applications: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_applicantstables: {organization_id_id: {_in: [${this.userOrgs}]}}}) {
            id
            begin_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorprograms: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    invitations: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
                is_jury
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
    incubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}, type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {user_id: {_eq: ${getUserData().id} }}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => {
        if (!data.users_organizationtable.usersAssociationtablesByAssociatedOrg) {
          // this.$bvModal.show('join-incubator-modal')
        }
        return data.users_organizationtable
      },
    },
    startupincubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    vbd: {
      query() {
        return gql`
          query Vbd {
            growth_category_startup_entry(where: {startup_id: {_eq: ${this.selectedOrgId}}, growth_categories_id: {_eq: ${this.selectedBd}}}, order_by: {id: desc}) {
              id
              title
              created_at
              description
            }
          }`
      },
      update: data => data.growth_category_startup_entry,
    },
    btns: {
      query() {
        return gql`
          query Btns {
            growth_categories(order_by: {id: asc}) {
              id
              title
              growth_custom_parameters(where: {startup_id: {_eq: ${this.selectedOrgId}}}, order_by: {id: asc}) {
                id
                is_private
                parameter
                input_type
              }
              growth_parameters(order_by: {id: asc}) {
                id
                title
                input_type
              }
            }
          }`
      },
      update(data) {
        this.selectedBd = data.growth_categories[0].id
        return data.growth_categories
      },
    },
    startupOrgs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        if (data.users_organizationtable.length === 1) {
          this.selectedOrgId = data.users_organizationtable[0].users_associationtables.id
        }
        data.users_organizationtable.forEach(orgs => {
          if (orgs.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
